import React, { Component } from "react";
import { connect } from "react-redux";
import Router from "../Components/Router";

export class Router_Cont extends Component {
  render() {
    return (
      <Router {...this.props} />
    );
  }
};

export const mapStateToProps = store => {
  return {
    login: store.login,
  };
};

export const mapDispatchToProps = dispatch => {
  return {

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router_Cont);