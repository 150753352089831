import React, { Component } from "react";
import Router from "./Router/Containers/Router_Cont";

class App extends Component {
  render() {
    return (
      <Router />
    );
  }
}
export default App;